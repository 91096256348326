<template>
  <v-container fluid class="">
    <!--  -->
    <h3 id="tituloCarga" class="text-center" color="white">
      DATOS DEL PROCESO
    </h3>
    <div class="row mt-3 mx-1">
      <div class="col-4" v-if="tipo != 'nuevo'">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          :readonly="tipo == 'ver'"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datosPrincipales.fecha_inicio"
              label="Fecha Registro de la Cotización"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <!-- <v-date-picker
            v-model="datosPrincipales.fecha_fin"
            @input="menu = false"
          ></v-date-picker> -->
        </v-menu>
      </div>
      <div class="col-4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :readonly="tipo == 'ver' || datosPrincipales.fecha_fin != null"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datosPrincipales.fecha_fin"
              label="Fecha Validez de la Cotización"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              :rules="[
                (v) => !!v || 'La fecha de validez es requerida',
                (v) =>
                  v > formatFechaHoy(hoy) || 'La fecha debe ser mayor que hoy',
              ]"
              :clearable="tipo != 'ver'"
            ></v-text-field>
          </template>
          <v-date-picker
            v-if="tipo != 'ver'"
            v-model="datosPrincipales.fecha_fin"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-4">
        <v-text-field
          type="number"
          name="txtTiempoTransito"
          id="txtTiempoTransito"
          label="Tiempo en Transito"
          min="1"
          :clearable="tipo != 'ver'"
          suffix="día(s)"
          :readonly="tipo == 'ver'"
          required
          class="widthTiempoTransito"
          :rules="[(v) => !!v || 'El Tiempo de transito es requerido']"
          v-model="datosPrincipales.tiempo_transito"
        ></v-text-field>
      </div>
    </div>
    <div
      class="row"
      v-if="
        status.filter((v) => v.value == datosPrincipales.idstatus)[0].code ==
          '10' ||
        status.filter((v) => v.value == datosPrincipales.idstatus)[0].code ==
          '14' ||
        status.filter((v) => v.value == datosPrincipales.idstatus)[0].code ==
          '9'
      "
    >
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 pa-0" :tipo="tipo">
        <QuoteSalesComponet />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 pa-0">
        <costo :tipo="tipo" />
      </div>

      <div class="col-12 col-md-5 col-lg-5 col-xl-5 pa-0">
        <venta :tipo="tipo" />
      </div>

      <div
        class="col-12 col-md-7 col-lg-7 col-xl-7 pa-0"
        v-if="
          this.services.filter((v) => v.codegroupservices == 15).length > 0
            ? this.services.filter((v) => v.codegroupservices == 15)[0].status
            : false
        "
      >
        <impuesto :tipo="tipo" />
      </div>
    </div>
    <v-flex text-right mt-5 v-if="tipo == 'nuevo'">
      <v-btn class="mx-1" color="#5AB55E" small scrollable @click="aceptar()">
        <b>Continuar</b> <v-icon class="">mdi-chevron-right</v-icon>
      </v-btn>
    </v-flex>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
import Swal from "sweetalert2";

export default {
  props: ["tipo"],
  mixins: [funcion],
  name: "cardCostosComponent",
  components: {
    costo: () => import("../../components/comun/opcionesRegistroComponent.vue"),
    venta: () => import("../../components/comun/ventasRegistroComponet.vue"),
    impuesto: () => import("../../components/comun/impuestoComponent.vue"),
    QuoteSalesComponet: () =>
      import("@/components/comun/quoteSalesComponet.vue"),
  },
  data() {
    return {
      hoy: new Date(),
      menu: false,
      menu2: false,
      dialog: false,
      dialogCosto: false,
      dialogVenta: false,
      panel: [0],
      opciones: [
        {
          id: "FGO",
          value: "FGO",

          text: " Flete y Gastos de origen",
        },
        {
          id: "GLN",
          value: "GLN",
          text: "Gastos Locales Navieros",
        },
        {
          id: "AS",
          value: "AS",

          text: "Aduanas",
        },
        {
          id: "AL",
          value: "AL",

          text: "Almacén",
        },
      ],

      headerImpuesto: [
        { value: "concepto", text: "Conceptos", align: "center" },
        { value: "percentaje", text: "Porcentaje", align: "center" },
        { value: "monto", text: "Costo Unitario ", align: "center" },
      ],
      items: [],
      totalOption: 0.0,
      resumenOpcion: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        amacen: 0,
      },
      resumenVenta: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        amacen: 0,
      },
    };
  },
  mounted() {},
  created() {},
  methods: {
    ...mapMutations(["updateDatosNotas", "setCostos"]),

    actualizarImpuesto() {
      let fob = null;
      let flet = null;
      let seguro = null;
      let cif = null;
      let valor = null;
      let adv = null;
      let isc = null;
      this.datosPrincipales.impuestos.forEach((element) => {
        valor = 0;
        switch (element.type) {
          case 1:
            valor = Math.round(this.datosPrincipales.amount * 100) / 100;
            fob = valor;
            break;
          case 2:
            valor =
              Math.round(this.resumenOpcion.flete * element.percentage) / 100;
            flet = valor;
            break;
          case 3:
            valor =
              Math.round(this.resumenOpcion.flete * element.percentage) / 100;
            seguro = valor;
            break;
          case 4:
            valor = fob + flet + seguro;
            cif = valor;
            break;
          case 5:
            valor = (cif * element.percentage) / 100;
            adv = valor;
            break;
          case 6:
            valor = ((cif + adv) * element.percentage) / 100;
            isc = valor;
            break;
          case 7:
            valor = ((cif + adv + isc) * element.percentage) / 100;
            break;
          case 8:
            valor = ((cif + adv + isc) * element.percentage) / 100;
            break;
          // break;
          default:
            break;
        }
        element.valor = valor;
      });
    },

    aceptar() {
      let costos = this.$store.state.costos.filter(
        (v) => v.status == 1 && v.esventaflag == 0
      );
      console.log(costos);
      if (
        costos.filter((v) => v.costounitario == 0).length == 0 &&
        this.datosPrincipales.fecha_fin &&
        this.datosPrincipales.tiempo_transito
      ) {
        this.updateDatosNotas();
      } else {
        if (costos.filter((v) => v.costounitario == 0).length > 0) {
          Swal.fire({
            icon: "warning",
            title: "ADVERTENCIA",
            text: "Existen costos sin montos, verifique.",
          }).then((result) => {
            if (result.isConfirmed) {
              this.menu = true;
            }
            if (result.dismiss) {
              this.menu = true;
            }
          });
        }
        if (!this.datosPrincipales.fecha_fin) {
          Swal.fire({
            icon: "warning",
            title: "ADVERTENCIA",
            text: "La fecha de validación es requierida",
          }).then((result) => {
            if (result.isConfirmed) {
              this.menu = true;
            }
            if (result.dismiss) {
              this.menu = true;
            }
          });
        }
        if (!this.datosPrincipales.tiempo_transito) {
          Swal.fire({
            icon: "warning",
            title: "ADVERTENCIA",
            text: "El tiempo de transito es requerido",
          }).then((result) => {
            if (result.isConfirmed) {
              document.getElementById("txtTiempoTransito").focus();
              // this.txtTiempoTransito.focus();
            }
            if (result.dismiss) {
              document.getElementById("txtTiempoTransito").focus();
              // this.txtTiempoTransito.focus();
            }
          });
        }
      }
      // this.updateDatosNotas();
    },
    formatFechaHoy(date) {
      let lengthMes = date.getMonth() + 1;
      if (lengthMes < 10) {
        lengthMes = "0" + lengthMes;
      }
      let formatted_date =
        date.getFullYear() + "-" + lengthMes + "-" + date.getDate();
      return formatted_date;
    },
  },

  computed: {
    ...mapState([
      "datosPrincipales",
      "begEndList",
      "roles",
      "multiplicador",
      "costos",
      "factor",
      "totalOpcion",
      "totalVenta",
      "totalImpuesto",
      "status",
      "services",
    ]),
  },
  updated() {
    // this.calcTotalOption();
  },
};
</script>
<style scoped>
.v-expansion-panels.condensed .v-expansion-panel-header {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: auto;
}
.widthTD {
  width: 150px !important;
}

/* .tdMontos {
  width:  !important; 
} */

td {
  padding: 0 3px !important;
  font-size: 5rem;
}
.derecha {
  text-align: right !important;
  align-content: right !important;
}
.v-text-field__slot {
  max-width: 5px !important;
}
.widthTiempoTransito {
  width: 30% !important;
}
.widthFecha {
  width: 50% !important;
}
</style>
