var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"text-center",attrs:{"id":"tituloCarga","color":"white"}},[_vm._v(" DATOS DEL PROCESO ")]),_c('div',{staticClass:"row mt-3 mx-1"},[(_vm.tipo != 'nuevo')?_c('div',{staticClass:"col-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","readonly":_vm.tipo == 'ver',"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Registro de la Cotización","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.datosPrincipales.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.datosPrincipales, "fecha_inicio", $$v)},expression:"datosPrincipales.fecha_inicio"}},'v-text-field',attrs,false),on))]}}],null,false,3926322723),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}})],1):_vm._e(),_c('div',{staticClass:"col-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","readonly":_vm.tipo == 'ver' || _vm.datosPrincipales.fecha_fin != null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Validez de la Cotización","prepend-icon":"mdi-calendar","rules":[
              function (v) { return !!v || 'La fecha de validez es requerida'; },
              function (v) { return v > _vm.formatFechaHoy(_vm.hoy) || 'La fecha debe ser mayor que hoy'; } ],"clearable":_vm.tipo != 'ver'},model:{value:(_vm.datosPrincipales.fecha_fin),callback:function ($$v) {_vm.$set(_vm.datosPrincipales, "fecha_fin", $$v)},expression:"datosPrincipales.fecha_fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.tipo != 'ver')?_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.datosPrincipales.fecha_fin),callback:function ($$v) {_vm.$set(_vm.datosPrincipales, "fecha_fin", $$v)},expression:"datosPrincipales.fecha_fin"}}):_vm._e()],1)],1),_c('div',{staticClass:"col-4"},[_c('v-text-field',{staticClass:"widthTiempoTransito",attrs:{"type":"number","name":"txtTiempoTransito","id":"txtTiempoTransito","label":"Tiempo en Transito","min":"1","clearable":_vm.tipo != 'ver',"suffix":"día(s)","readonly":_vm.tipo == 'ver',"required":"","rules":[function (v) { return !!v || 'El Tiempo de transito es requerido'; }]},model:{value:(_vm.datosPrincipales.tiempo_transito),callback:function ($$v) {_vm.$set(_vm.datosPrincipales, "tiempo_transito", $$v)},expression:"datosPrincipales.tiempo_transito"}})],1)]),(
      _vm.status.filter(function (v) { return v.value == _vm.datosPrincipales.idstatus; })[0].code ==
        '10' ||
      _vm.status.filter(function (v) { return v.value == _vm.datosPrincipales.idstatus; })[0].code ==
        '14' ||
      _vm.status.filter(function (v) { return v.value == _vm.datosPrincipales.idstatus; })[0].code ==
        '9'
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-7 col-xl-7 pa-0",attrs:{"tipo":_vm.tipo}},[_c('QuoteSalesComponet')],1)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-7 col-xl-7 pa-0"},[_c('costo',{attrs:{"tipo":_vm.tipo}})],1),_c('div',{staticClass:"col-12 col-md-5 col-lg-5 col-xl-5 pa-0"},[_c('venta',{attrs:{"tipo":_vm.tipo}})],1),(
        this.services.filter(function (v) { return v.codegroupservices == 15; }).length > 0
          ? this.services.filter(function (v) { return v.codegroupservices == 15; })[0].status
          : false
      )?_c('div',{staticClass:"col-12 col-md-7 col-lg-7 col-xl-7 pa-0"},[_c('impuesto',{attrs:{"tipo":_vm.tipo}})],1):_vm._e()]),(_vm.tipo == 'nuevo')?_c('v-flex',{attrs:{"text-right":"","mt-5":""}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"#5AB55E","small":"","scrollable":""},on:{"click":function($event){return _vm.aceptar()}}},[_c('b',[_vm._v("Continuar")]),_vm._v(" "),_c('v-icon',{},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }